<!-- 门票历史 -->
<template>
  <div class="ticket-history app-container">
    <el-form label-width="130px">
      <div v-if="NowStep === 1">
        <!-- 门票历史 -->
        <p style="
          text-align: left;
          font-size: 30px;
          margin-top: 0;
          margin-bottom: 10px;
        ">{{$t('Ticket.history')}}</p>
        <el-table v-loading="tableLoading" style="width: 100%" :data="list" size="mini" border fit highlight-current-row max-height="380">
          <!-- 操作 -->
          <el-table-column :label="$t('sysOperationLog.moduleName')" align="center">
            <template slot-scope="{row}">
              <!--          查看-->
              <el-button
                  :loading="btnLoading"
                  :title="$t('button.view')"
                  type="primary"
                  icon="el-icon-search"
                  circle
                  size="small"
                  @click="viewBtn(row)"/>
            </template>
          </el-table-column>
          <!--门票产品名称-->
          <el-table-column :label="$t('Ticket.product.name')" prop="name" align="center"/>
          <!-- 经销商编号 -->
          <el-table-column :label="$t('miMember.memberNo')" prop="memberNo" align="center"/>
          <!--      姓名-->
          <el-table-column :label="$t('person.name')" prop="name" align="center" />
          <!-- 分公司 -->
          <el-table-column :label="$t('miMember.company')" prop="companyCode" align="center"/>
          <!-- 创建时间 -->
          <el-table-column :label="$t('Common.createTime')" prop="createdTime" align="center"/>
          <!-- 门票总金额 -->
          <el-table-column :label="$t('Ticket.total.amount')" prop="totalAmount" align="center"/>
        </el-table>
      </div>
      <div v-if="NowStep === 2">
        <!-- 门票详情 -->
        <p style="
          text-align: left;
          font-size: 30px;
          margin-top: 0;
          margin-bottom: 10px;
        ">{{$t('Ticket.details')}}</p>
        <table class="table-css">
          <tr>
            <th colspan="2">{{$t('Ticket.details')}}</th>
          </tr>
          <tr>
            <td>
              <el-form class="orderlists">
                <!-- 门票产品名称 -->
                <el-form-item :label="$t('Ticket.product.name')">
                  {{ viewObj.name}}
                </el-form-item>
                <!-- 经销商编号 -->
                <el-form-item :label="$t('miMember.memberNo')">
                  {{ viewObj.memberNo}}
                </el-form-item>
                <!-- 付款人 -->
                <el-form-item :label="$t('poOrder.payerCode')">
                  {{ viewObj.payerCode}}
                </el-form-item>
                <!-- 付款人 -->
                <el-form-item :label="$t('poOrder.payerName')">
                  {{ viewObj.name}}
                </el-form-item>
                <!-- 门票购买时间 -->
                <el-form-item :label="$t('Ticket.purchase.time')">
                  {{ viewObj.createdTime}}
                </el-form-item>
                <!-- 付款方式 -->
                <el-form-item :label="$t('fiPayAdvice.dealType')">
                  <span v-if="viewObj.paymentMethod === 1">
                    {{ $t('bonus.wallet') }}
                  </span>
                </el-form-item>
                <!-- 门票总金额 -->
                <el-form-item :label="$t('Ticket.total.amount')">
                  {{ viewObj.totalAmount}}
                </el-form-item>
              </el-form>
            </td>
          </tr>
        </table>
        <!-- 产品信息 -->
        <table class="tableInfo-css">
          <tr>
            <!--     门票产品名称       -->
            <th>{{$t('Ticket.product.name')}}</th>
            <!--     门票产品编码       -->
            <th>{{$t('Ticket.product.code')}}</th>
            <!--     购买数量       -->
            <th>{{$t('Purchase.quantity')}}</th>
            <!--     门票单价       -->
            <th>{{$t('Ticket.Unit.Price')}}</th>
          </tr>
          <tr v-for="a in listINfo" :key="a.id">
            <td style="text-align: left">{{ a.name }}</td>
            <td style="text-align: left">{{ a.goodscode }}</td>
            <td style="text-align: right">{{ a.qty }}</td>
            <td style="text-align: right">{{ a.unitAmount }}</td>
          </tr>
          <tr>
            <td colspan="3" style="text-align: right">{{$t('bdSendEfuboBonusDetail.amtCount')}}</td>
            <td style="text-align: right">
              <span>{{ viewObj.totalAmount }} &nbsp;</span>
            </td>
          </tr>
        </table>
          <!-- 返回 -->
          <el-button type="warning" plain style="float: right; margin: 10px 0" @click="btnReturn">{{$t('operation.button.return')}}</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
    import { orderTicketView, orderTicketPage } from '@/api/orderTicket/orderticket'
    export default {
        name: "TicketHistory",
        data () {
            return {
                NowStep: 1,
                viewObj: {},
                listINfo: [],
                list: [],
                tableLoading: false,
                btnLoading: false
            }
        },
        created() {
          this.getList()
        },
        methods: {
            getList() {
                this.tableLoading = true
                orderTicketPage().then(res => {
                    this.list = res.data.records
                    this.tableLoading = false
                }).catch(() => {
                    this.tableLoading = false
                })
            },
            viewBtn(row) {
                this.btnLoading = true
                orderTicketView({ 'id': row.id }).then(res => {
                    this.viewObj = res.data
                    this.listINfo = res.data.details
                    this.NowStep = 2
                    this.btnLoading = false
                }).catch(() => {
                    this.btnLoading = false
                })
            },
            btnReturn () {
                this.NowStep = 1
            },
        },
    };
</script>

<style scoped lang="scss">
  .orderlists{
    ::v-deep .el-form-item{
      margin-bottom: 0;
    }
  }
  .table-css {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    tr {
      th {
        padding: 5px;
        width: 150px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        border: 1px solid #eeeeee;
        text-align: left;
        background: #dddddd;
      }
      td {
        padding: 5px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        border: 1px solid #dddddd;
        text-align: left;
        background: #ffffff;
        span {
          font-weight: 800;
        }
      }
    }
  }
  .tableInfo-css {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    tr {
      th {
        padding: 5px;
        width: 150px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        border: 1px solid #eeeeee;
        text-align: center;
        background: #dddddd;
      }
      td {
        padding: 5px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        border: 1px solid #dddddd;
        background: #ffffff;
        span {
          font-weight: 800;
        }
      }
    }
  }
</style>
